import {
    Category,
    CATEGORY_ITEM_TYPE,
    CategoryItem,
    GroupedArticleByLevel,
    Ingredient,
    MenuItem,
    SelectedIngredient,
    TVA,
    TypeOrderPrice
} from "../interfaces/Catalogue";
import { MODE_DELIVERY, MODE_DRIVE, MODE_ONSITE, MODE_TAKEAWAY } from "./deliveryUtils";

// PRICE:
export const findPriceByTypeOrder = (
    typeOrderCategory: TypeOrderPrice[],
    typeOrder: number
): number | undefined => {
    const item = typeOrderCategory?.find(
        (orderCategory) => orderCategory.type === typeOrder
    );
    return item?.price || 0;
};

export const getPriceByTypeOrderForComposition = (typeOrder: number, articleComposition: Ingredient): number => {
    switch (typeOrder) {
        case MODE_ONSITE: // Sur Place
            return articleComposition.priceOnSite ?? 0;
        case MODE_TAKEAWAY: // A Emporter
            return articleComposition.priceEmporter ?? 0;
        case MODE_DELIVERY: // Livraison
            return articleComposition.priceDelivery ?? 0;
        default:
            return 0;
    }
}

// TVA:
export const getTvaByOrderType = (orderType: number, tvaObj: any): TVA => {
    switch (orderType) {
        case MODE_ONSITE:
            return tvaObj.tvaOnSite;
        case MODE_TAKEAWAY:
            return tvaObj.tvaEmporter;
        case MODE_DELIVERY:
            return tvaObj.tvaDelivery;
        default:
            throw new Error('Invalid order type');
    }
};

export const groupMenuItemsByLevel = (
    menuItems: MenuItem[],
    typeOrder: number
): GroupedArticleByLevel[] => {
    const levelMap: { [levelId: number]: GroupedArticleByLevel } = {};

    // Iterate over the menu items to group them by `levelId`.
    menuItems.forEach(item => {
        const { levelId, level } = item;

        if (!levelMap[levelId]) {
            levelMap[levelId] = { ...level, menuItems: [] };
        }

        if (!item.isNotImportant) {
            levelMap[levelId].menuItems!.push(item);
            const existingTypeOrderIds = levelMap[levelId].typeOrderLevel!.map(item => item.typeOrderId);

            item.level.typeOrderLevel.forEach(typeOrder => {
                if (!existingTypeOrderIds.includes(typeOrder.typeOrderId)) {
                    levelMap[levelId].typeOrderLevel!.push(typeOrder);
                }
            });
        }
    });

    // Convert the `levelMap` into an array and sort groups by `orderIndex`.
    const sortedItems = Object.values(levelMap).sort((a, b) => a.orderIndex - b.orderIndex);

    // Sort menu items within each group by their `orderIndex`.
    sortedItems.forEach(level => {
        level?.menuItems?.sort((a, b) => a.orderIndex - b.orderIndex);
    });

    // Filter sorted groups based on `typeOrder` in their `typeOrderLevel` array.
    const ObjectSortedItems = Object.values(sortedItems)?.filter((level) =>
        level?.typeOrderLevel?.some((item) => item?.type === typeOrder)
    );

    const filteredAndMappedItems = ObjectSortedItems.map(level => {
        // Filter menu items based on `typeOrder` in their `typeOrderPrices` array.
        level.menuItems = level?.menuItems?.filter(menuItem =>
            menuItem.typeOrderPrices.some(price => price.type === typeOrder)
        );
        return level;
    });

    return filteredAndMappedItems.filter(level => (level?.menuItems?.length || 0) > 0);
};

export const getOrderTypeText = (typeOrder: number): string | null => {
    if (!typeOrder) return null;

    switch (typeOrder) {
        case MODE_TAKEAWAY:
            return "À Emporter"; // Takeaway
        case MODE_ONSITE:
            return "Sur place"; // Onsite
        case MODE_DELIVERY:
            return "Livraison"; // Delivery
        case MODE_DRIVE:
            return "Drive"; // Drive
        default:
            return "Mode inconnu"; // Unknown mode
    }
}

// Utility function to compare arrays (e.g., for checking if ingredients are the same)
export const arraysEqual = (arr1: SelectedIngredient[], arr2: SelectedIngredient[]): boolean => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => item.id === arr2[index].id); // Adjust comparison based on actual properties
};

export const getTotalOrder = (categories: CategoryItem[]): number => {
    const calculatedTotal =
        categories?.length > 0
            ? categories?.reduce((total: number, category) => {
                return (
                    total + (category?.finalUnitPrice || 0) * (category?.quantity || 0)
                );
            }, 0)
            : 0;

    return parseFloat(calculatedTotal.toFixed(2))
}

export const getCategoryItemType = (type: string) => {
    return {
        isMenu: type === CATEGORY_ITEM_TYPE.MENU,
        isArticle: type === CATEGORY_ITEM_TYPE.ARTICLE,
    };
};

export const filterItemsByTypeOrder = (categories: Category[], typeOrder: number): Category[] => {
    return categories
        ?.filter((category) =>
            category.typeOrderFamily.some((family) => family.type === typeOrder)
            && (category.categoryArticlesCount > 0 || category.categoryMenusCount > 0)
        )
        ?.sort((a, b) => a.orderIndex - b.orderIndex);
};

