import { AllergenPayload, Client, Cmp, CommandGroupProduct, CreateDeliveryRequest, EcranPayload, OrderPayload, PayOp, Personel, ProductCategory, Remise } from "../interfaces/apiPayloads";
import {
    Allergen,
    CategoryItem,
    OrderSummary,
    RestaurantCart,
    Screen,
    SelectedIngredient
} from "../interfaces/Catalogue";
import { UserInfo } from "../interfaces/user";
import { findPriceByTypeOrder, getPriceByTypeOrderForComposition, getTvaByOrderType, } from "../utils";
import { extractClientAddress, MODE_DELIVERY, MODE_TAKEAWAY } from "../utils/deliveryUtils";

const TypeCmdEmpValues = {
    delivery: 10,
    other: 8,
};

export const CONFIG = {
    IdUser: 2,
    TypeCmdApp: 2
};

export const mapToOrderPayload = (
    restaurantCart: RestaurantCart,
    totalOrder: number,
    isOnlinePaymentChecked: boolean,
    isMaintenantChecked: boolean,
    prepareFor: string | null,
    userInfo: any,
    createDeliveryRequest?:CreateDeliveryRequest,
    completeAddress?:string
): OrderPayload => {
    const commandGroupProducts = mapToCommandGroupProducts(restaurantCart, isMaintenantChecked);

    const date = new Date().toISOString()

    const payOps: PayOp[] = [
        {
            "IdD": 0,
            "IdType": 2,
            "Name": isOnlinePaymentChecked ? "Online" : "Espèces",
            "Value": 93,
            "IsLock": false,
            "IsRbs": false,
            "IsAnnuled": false,
            "IsNnEdt": false,
            "IsAuto": false,
            "IdAvOrigin": 0,
            "CodeTr": null,
            "TpeTicket": null,
            "TpeNumMsg": 0,
            "TpeMsg": null,
            "TpeType": null,
            "TicketCommercant": null
        }
    ]

    const client: any = {
        FideliteCumul: 0,
        Nm: `${userInfo?.firstName} ${userInfo?.lastName}`,
        T: userInfo?.phoneNumber,
        Eml: userInfo?.email,
        Ad: completeAddress || '',
        Cc: createDeliveryRequest?.clientAddress.zip_code,
        B: createDeliveryRequest?.clientAddress.appartement,
        In: "",
        Es: "",
        PortePo: ""
    }

    const remise: Remise = {
        "IdD": 0,
        "Id": 0,
        "IsPct": false,
        "IsValid": false,
        "Amount": 0.0,
        "Min": 0.0
    }

    const personel: Personel = {
        "i": 0,
        "u": null,
        "r": 0.0
    }

    const orderInfo = {
        "IdTypePv": 2,
        "IdRestaurant": restaurantCart.restaurant.id,
        "IdUser": CONFIG.IdUser,
        "IdMachine": 51,
        "IdBeeper": 0,
        "IdCommand": 0,
        "TakeAway": restaurantCart.mode == MODE_TAKEAWAY,
        "ToBeDelivered": restaurantCart.mode == MODE_DELIVERY,
        "TotalNet": totalOrder,
        "TotalCommand": totalOrder,
        "PanierEncaisse": totalOrder,
        "PanierApayer": 0,
        "PanierArendre": 0,
        "ClientPsdName": null,
        "TypeCmdApp": CONFIG.TypeCmdApp,
        "TypeCmdEmp": restaurantCart.mode == MODE_DELIVERY ? TypeCmdEmpValues.delivery : TypeCmdEmpValues.other,
        "TypeCmdLivTiers": 0,
        "DecalageCmdDt": prepareFor,
        "DecalageCmdKtcMin": 0,
        "Client": client,
        "CommandGroupProducts": commandGroupProducts,
        // "PayOps": payOps,
        "PayOps": null,
        "Remise": remise,
        "DeliveryIdClientAddress": 0,
        "NumCaisse": 0,
        "FideliteTotalPointsProd": 0,
        "FideliteTotalPointsRetrait": 0,
        "AppliedPromotion": null,
        "IsPromotionApplied": false,
        "IsRemiseApplied": false,
        "Chevalet": null,
        "PhoneNumber": userInfo?.phoneNumber,
        "ToBeCollectedViaDrive": false,
        "IdLanguage": 0,
        "Id": 0,
        "CommandDate": date,
        "DatemajPrete": date,
        "NumCmd": 0,
        "IsPrete": false,
        "IsDeleted": false,
        "IsTotallyPrepared": false,
        "IsLivred": false,
        "DateLivraison": null,
        "Location": "Mobile/Web",
        "IsPriority": false,
        "IsAsuivrePlustard": false,
        "CanShowKitchen": true,
        "IsPaid": true,
        "Personel": personel,
        "IsRepas": false,
        "Annuled": null,
        "IsMobile": false,
        "PaymentDate": date,
        "IdChangePvt": 0,
        "CountUpdate": 0,
        "IdClient": 0
    }

    console.log("orderInfo", orderInfo)
    return orderInfo

}

export const mapToCommandGroupProducts = (restaurantCart: RestaurantCart, isMaintenantChecked: boolean): CommandGroupProduct[] => {
    const { categories, mode } = restaurantCart
    const commandGroupProducts: CommandGroupProduct[] = []


    categories?.map((category) => {

        const allSelectedArticles = category?.order?.flatMap(orderItem => orderItem.selectedArticles) || [];

        const date = new Date().toISOString()
        const categoryTVA = getTvaByOrderType(mode, category)


        let orderGroup: CommandGroupProduct = {
            NameMenu: allSelectedArticles[0]?.selecteMenuItem.name || category.designation,
            Identity: "G1", //FIXME:
            Quantity: category?.quantity || 1,
            TotalMenu: category?.finalUnitPrice ?? 0,
            IsOffred: false,
            GroupRemisePct: 0,
            GroupRemiseMt: 0,
            GroupRemiseFinalPct: 0,
            GroupRemiseFinalMt: 0,
            CcnOptimiz: true,
            IdCategory: category?.id,
            DateMiseJour: date,
            UpdateMaj: true,
            ProductCategory: [],
            HasAllergene: category?.hasAllergene || false,
            Allergenes: mapAllergenes(category?.articleAllergen ?? []),
            IsFideliteApplied: false,
            Symbol: null,
            IdTva: categoryTVA?.id,
            Tva: categoryTVA?.taux,
            IdGroup: 0, //FIXME:
            DispchDateMaj: date,
            DispchDateLock: date,
            DispchIdUser: 0,
            DispchGroupTypePrior: 0,
            DispchIsPrete: false,
            KitchenFrColor: category.kitchenFrColor,
            CuisineInfo: null
        }


        if (allSelectedArticles.length == 0) {

            orderGroup.ProductCategory?.push({
                IdD: 0,
                IdRef: category.idRef,
                Name: category.designation,
                ImageUrl: category.imgUrl,
                IdFamily: category.categoryId,
                IdCategory: 0,
                IdLevel: 0,
                Quantity: 1,
                Price: category.unitPrice || 0,
                NotImportant: false,
                CanBeUpdated: false,
                HasFlOp: false,
                IsForLater: false,
                IsOfr: false,
                OffertAchatId: 0,
                OffertNbAchete: 0,
                OffertNbOffert: 0,
                OffertIsPct: false,
                OffertMontant: 0,
                PcRemisePct: 0,
                PcRemiseMontant: 0,
                PcTotalRemisePct: 0,
                PcTotalRemiseMt: 0,
                HsCmp: category.hasComposition || false,
                PcFidelitePointsProd: 0,
                PcFidelitePointsRetrait: 0,
                PcFidelitePointsPrice: 0,
                CpFidelitePointsProd: 0,
                CpFidelitePointsRetrait: 0,
                CpFidelitePointsPrice: 0,
                OrderIndex: 0,
                Cmps: mapIngredient(category?.selectedIngredients || [], category?.eliminatedIngredients || [], mode),
                HasAllergene: category.hasAllergene || false,
                OfIsApply: false,
                Weight: 0,
                CmpMxSlct: category?.maxNbrSelectionComposition || 0,
                Symbol: undefined,
                IdTva: categoryTVA?.id,
                Tva: categoryTVA?.taux,
                IsTotallyPrepared: false,
                Ecrans: category.screens == null ? [] : mapScreens(category.screens),
                CanAffProductInEcrPrepGnrQt: false,
                DispatchEtatTypeLigne: 0,
                IdPrds: category?.id,
                KitchenFrColor: category?.kitchenFrColor,
                IdType: category?.familyId,
                TypeName: category?.familyName,
                IdSubType: category?.subFamilyId,
                SubTypeName: category?.subFamilyName
            })

        } else {
            allSelectedArticles?.map((selectedArticle, index) => {
                const { selecteMenuItem, selectedIngredients, eliminatedIngredients } = selectedArticle
                const tva = getTvaByOrderType(mode, selecteMenuItem)

                const productCategoryItem: ProductCategory = {
                    IdD: 0,
                    IdRef: selecteMenuItem?.levelArticleId,
                    Name: selecteMenuItem?.name,
                    ImageUrl: selecteMenuItem?.imageUrl,
                    IdFamily: selecteMenuItem?.idCategory,
                    IdCategory: selecteMenuItem?.idMenu,
                    IdLevel: selecteMenuItem.levelId,
                    Quantity: selecteMenuItem.quantity || 1,
                    Price: findPriceByTypeOrder(selecteMenuItem.typeOrderPrices, mode) || 0,
                    NotImportant: selecteMenuItem.isNotImportant,
                    CanBeUpdated: false,
                    HasFlOp: false,
                    IsForLater: false,
                    IsOfr: false,
                    OffertAchatId: 0,
                    OffertNbAchete: 0,
                    OffertNbOffert: 0,
                    OffertIsPct: false,
                    OffertMontant: 0,
                    PcRemisePct: 0,
                    PcRemiseMontant: 0,
                    PcTotalRemisePct: 0,
                    PcTotalRemiseMt: 0,
                    HsCmp: category.hasComposition || false,
                    PcFidelitePointsProd: 0,
                    PcFidelitePointsRetrait: 0,
                    PcFidelitePointsPrice: 0,
                    CpFidelitePointsProd: 0,
                    CpFidelitePointsRetrait: 0,
                    CpFidelitePointsPrice: 0,
                    OrderIndex: selecteMenuItem?.orderIndex,
                    Cmps: mapIngredient(selectedIngredients, eliminatedIngredients, mode),
                    HasAllergene: selecteMenuItem?.articleAllergen?.length > 0,
                    Allergenes: mapAllergenes(selecteMenuItem?.articleAllergen ?? []),
                    OfIsApply: false,
                    Weight: 0,
                    CmpMxSlct: 0,
                    Symbol: undefined,
                    IdTva: tva?.id,
                    Tva: tva?.taux,
                    IsTotallyPrepared: false,
                    Ecrans: selecteMenuItem?.screens == null ? [] : mapScreens(selecteMenuItem?.screens),
                    CanAffProductInEcrPrepGnrQt: false,
                    DispatchEtatTypeLigne: 0,
                    IdPrds: selecteMenuItem.id,
                    KitchenFrColor: selecteMenuItem?.kitchenColor,
                    IdType: selecteMenuItem?.familyId,
                    TypeName: selecteMenuItem?.familyName,
                    IdSubType: selecteMenuItem?.subFamilyId,
                    SubTypeName: selecteMenuItem?.subFamilyName,
                    NiveauName: selecteMenuItem?.levelName,
                }

                orderGroup.ProductCategory?.push(productCategoryItem)
            })

        }



        commandGroupProducts.push(orderGroup)
    })


    return commandGroupProducts;
};

export const mapIngredient = (selectedIngredients: SelectedIngredient[], eliminatedIngredients: SelectedIngredient[], typeOrder: number): Cmp[] => {
    const nonIncludedIngredients = selectedIngredients?.filter(ingredient => ingredient.ingredient.inclus === false)
    const mergedIngredients = [...nonIncludedIngredients, ...eliminatedIngredients]

    return mergedIngredients?.map((selectedIngredient, index): Cmp => {
        const { ingredient, quantity } = selectedIngredient;
        const tva = getTvaByOrderType(typeOrder, ingredient);

        return {
            IdD: 0,
            IdRef: ingredient.id,
            Name: ingredient.name,
            Image: ingredient.imageUrl,
            IsActive: ingredient.isActif,
            Price: getPriceByTypeOrderForComposition(typeOrder, ingredient),
            MaxSelect: ingredient.maxNbrSelection,
            Quantity: quantity,
            DefaultEtat: selectedIngredient.quantity === 0 ? true : false,
            PccTotalRemisePct: 0,
            PccTotalRemiseMt: 0,
            BckColor: null,
            FrontColor: null,
            OrderIndex: ingredient.orderIndex,
            HasAllergene: (ingredient.allergen?.length ?? 0) > 0,
            Allergenes: mapAllergenes(ingredient?.allergen ?? []),
            IdTva: tva?.id,
            Tva: tva?.taux,
        };
    });

};

export const mapAllergenes = (allergenes: Allergen[]): AllergenPayload[] => {
    return allergenes?.map(({ id, name, imageUrl }) => {
        return {
            Id: id,
            Name: name,
            ImageUrl: imageUrl,
            Annuler: false,
            IsDeleted: false
        };
    });
};

export const mapScreens = (screens: Screen[]): EcranPayload[] | null => {
    if (!screens) return [];

    return screens?.map((screen) => {
        return {
            Id: screen.id,
            Nm: screen.designation,
            CanAffichCmdNonPrete: screen.canShowNotReadyOrder,
            IsPreteAffich: screen.canShowReadyOrder,
            EcranCanAffichCmdAnticip: screen.canShowAnticipateOrder,
            IsAffichDispatch: screen.activateDispatching,
            IsDefaultEcran: false,
            DispGroupAffNonPrete: screen.showNotReadyGroup,
            DispGroupAffPrete: screen.showReadyGroup,
            DispGroupAffOneMaj: false,
            TypeCmdAfficher: 0,
            CanAffPrQt: false,
            CanAffCpQt: false,
            canAffInEcrPrepGnrQt: false,
            affInEcrPrepGnrBcGrColor: null,
        };
    });
}

export const mapToOrderSummary = (categories: CategoryItem[]): OrderSummary[] => {
    return categories?.map((category) => {
        return {
            item: category.designation,
            quantity: category.quantity ?? 0,
            unitPrice: category.finalUnitPrice ?? 0,
            amount: (category.finalUnitPrice ?? 0) * (category.quantity ?? 0),
        };
    });
};

export const mapToCreateDeliveryRequest = (
    deliveryAddress: google.maps.places.PlaceResult | undefined,
    quoteId: string,
    userInfo: UserInfo,
    estimatedDeliveryQuoteMinutes: number
): CreateDeliveryRequest => {
    const clientAddress = extractClientAddress(deliveryAddress || {})
    const location = deliveryAddress?.geometry?.location;
    let lat: number = 0;
    let lng: number = 0;

    if (location) {
        if (typeof location.lat === 'function' && typeof location.lng === 'function') {
            lat = location.lat();
            lng = location.lng();
        } else {
            lat = location?.lat as any ?? 0;
            lng = location?.lng as any ?? 0;
        }
    }

    return {
        quoteId: quoteId,
        clientLatitude: lat,
        clientLongitude: lng,
        clientAddress,
        clientPhoneNumber: userInfo.phoneNumber || '',
        clientName: `${userInfo.firstName} ${userInfo.lastName}`,
        orderDeliveryBeginDate: new Date().toISOString(),
        estimatedDeliveryQuoteMinutes : Math.floor(estimatedDeliveryQuoteMinutes),
    }
}
