import { useEffect, useState } from "react";
import { ApiResponse } from "../../../interfaces/ApiResponse";
import {
  Category,
  CategoryItem,
  Restaurant,
} from "../../../interfaces/Catalogue";
import { catalogueApi } from "../../../services/catalogueApi";
import MenuList from "../MenuList/MenuList";

interface MenuRenderProps {
  category: Category;
  typeOrder: number;
  restaurant: Restaurant;
}

export const MenuRender = (props: MenuRenderProps) => {
  const { category, typeOrder, restaurant } = props;
  const [categoryItems, setCategoryItems] = useState<CategoryItem[] | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        setLoading(true);
        const response: { data: ApiResponse<CategoryItem[]> } =
          await catalogueApi.getCategoryDetails(
            category?.id,
            restaurant?.azureUrl
          );
        const result = response.data.data.result;

        const sortedResult = result.sort((a, b) => a.orderIndex - b.orderIndex);
        setCategoryItems(sortedResult);
      } catch (error: any) {
        console.error("🚀 ~ fetchCategoryDetails ~ error:", error);
        // const { response } = error;
        // const errorCodeFI = response?.data?.failureResponse?.codeFI?.code;
        // const errorCodeReq = response?.status;
        // setError(errorCodeFI || errorCodeReq)
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryDetails();
  }, [category?.id]);
   

  return (
    <MenuList
      loading={loading}
      categoryItems={categoryItems}
      categoryId={category?.id}
      typeOrder={typeOrder}
      restaurantLogo={restaurant?.imageUrl}
      restaurantId={restaurant?.id}
    />
  );
};
