import { Checkbox, FormControlLabel } from "@mui/material";
import { Ingredient } from "../../../../../../interfaces/Catalogue";
import { getPriceByTypeOrderForComposition } from "../../../../../../utils";
import "./IngredientCheckbox.scss";

interface IngredientCheckboxProps {
  ingredient: Ingredient;
  isSelected: boolean;
  typeOrder: number;
  onChange: () => void;
  disabled: boolean;
  className?: string;
}

const IngredientCheckbox = (props: IngredientCheckboxProps) => {
  const { ingredient, isSelected, onChange, disabled, typeOrder, className } =
    props;

  const priceComposition = getPriceByTypeOrderForComposition(
    typeOrder,
    ingredient
  );

  return (
    <FormControlLabel
      className="ElementComposition"
      label={
        <div
          className={className}
          style={{
            display: "flex",
            flexDirection: "column",
            color: disabled ? "gray" : "inherit",
            opacity: disabled || ingredient.isOutOfStock ? 0.5 : 1,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <span>
              {ingredient?.name.charAt(0).toUpperCase() +
                ingredient?.name.slice(1).toLowerCase()}
            </span>
            <span className="price-el">
              {priceComposition > 0 && `+${priceComposition} €`}
            </span>
          </div>
        </div>
      }
      control={
        ingredient.isOutOfStock ? (
          <span className="epuise">Épuisé</span>
        ) : (
          <Checkbox
            checked={isSelected}
            onChange={onChange}
            disabled={disabled || ingredient.isOutOfStock}
          />
        )
      }
    />
  );
};

export default IngredientCheckbox;
