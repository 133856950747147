
interface loadConfigProps  {
  companyId: string
  api_baseUrl: string
  api_catalogue: string
  key_google: string
  stripe_public_key: string
  mapObject: {
    key: string,
    language: string,
    components: string,
    type: string,
  }
  withCategoryWheel: boolean;
  hasDarkBackground: boolean;
  showSearchBar: boolean;
}

export const loadConfig = async (): Promise<loadConfigProps> => {

  try {
    const response = await fetch("/config.json");
    const config: loadConfigProps = await response.json();
    return config;
  } catch (error) {
    console.error("Error loading config:", error);
    throw new Error("Failed to load API configuration.");
  }
};

export default loadConfig;
