import { Container, Grid } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import RestaurantItem from "../../components/RestaurantListPage/RestaurantList/RestaurantItem";
import CategoryWheel from "../../components/RestaurantListPage/categorieWheel/CategoryWheel";
import AppNavbar from "../../components/shared/AppNavbar/AppNavbar";
import { useConfig } from "../../context/ConfigContext";
import withNavigateHook from "../../hooks/withNavigateHook";
import {
  addShopToFavorite,
  categoryShopsChangeShopMode,
  currentCategoryChange,
  getFiltredShops,
  getmyprofile,
  logout,
  updateCartListById,
} from "../../store/actions/actions";
import "./RestaurantListPage.scss";
import { typeOrderTabs } from "../../utils/deliveryUtils";

const RestaurantListPage = (props: any) => {
  const { config } = useConfig();
  const [showCategoryWheel, setShowCategoryWheel] = useState(false);
  const [shopsFio, SetShopsFio] = useState<any[]>(props.shops);
  const [RestorentFilter, setRestorentFilter] = useState("");
  const changeColor = true;
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [diningMode, setDiningMode] = useState(2);
  const location = useLocation();
  const cls = visible ? "visible" : "hidden";
  localStorage.setItem("stateChanged", String(changeColor));

  useEffect(() => {
    if (location.pathname === "/restaurants") {
      localStorage.removeItem("restaurantId");
    }

    props.getmyprofile();
  }, []);

  useEffect(() => {
    if (props?.shops) {
      SetShopsFio(props.shops);
    }
  }, [props?.shops]);

  useEffect(() => {
    let body: serviceShopModels.getFiltredShopBody = {
      // INFO: diningMode Commented for FioEat Marque Blanch because we don't need to filter restaurants of the company.
      // diningMode: diningMode,
      categories: props.currentCategory,
      adresse: {
        latitude: props.location?.lat,
        longitude: props.location?.lng,
      },
    };
    // if(config?.companyId && config?.companyId !== ""){
    props.getFiltredShops({ ...body }, config?.companyId);
    // }
  }, [
    props.shopMode,
    props.currentCategory,
    props.location?.shownAddress,
    diningMode,
    config?.companyId,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      let offsetY = window.pageYOffset;
      let moving = window.pageYOffset;

      if (offsetY > 130) {
        setVisible(position > moving);
        setPosition(moving);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    console.log("config?.withCategoryWheel", config?.withCategoryWheel);
    if (
      config?.withCategoryWheel != null &&
      config?.withCategoryWheel === true
    ) {
      setShowCategoryWheel(true);
    } else {
      setShowCategoryWheel(false);
    }
  }, [config?.withCategoryWheel]);

  return (
    <section>
      <AppNavbar
        setRestorentFilter={setRestorentFilter}
        RestorentFilter={RestorentFilter}
      />

      <Grid
        item
        xs={12}
        md={8}
        lg={8}
        xl={8}
        container
        justifyContent="center"
        alignItems="center"
      ></Grid>

      <Container className="restaurant-container">
        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{ margin: "auto", marginBottom: "5rem" }}
        >
          <div className="listing-restaurant-header">
            <div className={`${cls} sticky-filter`}>
              {/* <section className="filter-cat-by-place delivery-type">
                {typeOrderTabs?.map((tab) => (
                  <p
                    key={tab.type}
                    className={diningMode === tab.type ? "active-tab" : ""}
                    onClick={() => setDiningMode(tab.type)}
                  >
                    {tab.label}
                  </p>
                ))}
              </section> */}
              {showCategoryWheel && <CategoryWheel />}
            </div>
          </div>

          <Grid item xs={12} className="listing-restaurants-container">
            <Grid
              className="listing-restaurants"
              container
              justifyContent="center"
              spacing={2}
              columnGap={"10px"}
              rowGap={"5px"}
            >
              {!props.homeLoading ? (
                <Fragment>
                  {shopsFio
                    ?.filter((shop: any) =>
                      shop.nom
                        .toLowerCase()
                        .includes(RestorentFilter.toLowerCase())
                    )
                    ?.map((shop: any, i: number) => (
                      <Grid
                        item
                        key={i}
                        className="row card-restaurant"
                        sx={{ marginBottom: 2, marginLeft: "-10px" }}
                      >
                        <RestaurantItem
                          shopsFio={shopsFio}
                          shop={shop}
                          id={shop.id}
                          onFavoriteChange={(idRestaurant: any, value: any) =>
                            props.addRestaurantToFavorite(idRestaurant, value)
                          }
                          nom={shop.nom}
                          imageUrl={shop.imageUrl}
                          isFavorite={shop.isFavorite}
                          isOpen={shop.isOpen}
                          distance={shop.distance}
                          tempsPrep={shop.tempsPrep}
                          note={shop.note}
                          shifts={shop.shifts}
                          typeOrder={diningMode}
                        />
                      </Grid>
                    ))}
                  {shopsFio?.filter((shop: any) =>
                    shop.nom
                      .toLowerCase()
                      .includes(RestorentFilter.toLowerCase())
                  ).length === 0 && (
                    <div className="no-results">
                      <img src="" />{" "}
                      <p
                        style={{
                          fontSize: "28px",
                          fontWeight: 600,
                          fontFamily: "Poppins",
                          color: "#1D2939",
                        }}
                      >
                        Aucun restaurant trouvé
                      </p>
                    </div>
                  )}
                </Fragment>
              ) : (
                // <Preloader />
                <div></div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    homeLoading: state.shop.homeLoading,
    shops: state.shop.shops,
    shopMode: state.shop.mode,
    currentCategory: state.category.currentCategory,
    restaurantNameFilter: state.shop.restaurantNameFilter,
    cartList: state.order.cartList,
    cartListById: state.order.cartListById,
    savedCategories: state.cart.savedCategories,
    userInfo: state.auth.userInfo,
    sendVerificationCodeResponse: state.auth.sendVerificationCodeResponse,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getFiltredShops: (
      body: serviceShopModels.getFiltredShopBody,
      companyId: string
    ) => dispatch(getFiltredShops(body, companyId)),
    getmyprofile: () => dispatch(getmyprofile()),
    addRestaurantToFavorite: (idRestaurant: number, value: boolean) =>
      dispatch(addShopToFavorite(idRestaurant, value)),
    cartRestById: (restaurantById: any) =>
      dispatch(updateCartListById(restaurantById)),
    logout: () => dispatch(logout()),
    changeCurrentCategory: (value: number) =>
      dispatch(currentCategoryChange(value)),
    changeCategoryShopMode: (value: number) =>
      dispatch(categoryShopsChangeShopMode(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(RestaurantListPage));
