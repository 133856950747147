import { Commande } from "../../models/services/module.order";
import { getOrderTypeText } from "../../utils";

export default function CartHeaderDetails({ order }: { order?: Commande }) {
  const orderTypeText = getOrderTypeText(order?.diningMode || 0);

  return (
    <div className="CartHeader">
      <div className="wrapper">
        <p className="restaurantName">{order?.nomRestaurant}</p>
        <div className="col-wrapper">
          <p className="restaurantAdresse">{orderTypeText}</p>
          <p className="articlesSlot">{order?.nbArticles} article(s)</p>
        </div>
      </div>
      <div className="seperator-cart"></div>
    </div>
  );
}
