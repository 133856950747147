import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Typography } from "@mui/joy";
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Ingredient,
  MenuItem,
  SelectedIngredient,
  SelectionMode,
} from "../../../../../../../interfaces/Catalogue";
import {
  findPriceByTypeOrder,
  getPriceByTypeOrderForComposition,
} from "../../../../../../../utils";
import IngredientModal from "../../Modals/IngredientModal/IngredientModal";
import "./MultiSelectionItem.scss";
import QuantityCounter from "./QuantityCounter/QuantityCounter";

interface MultiSelectionItemProps {
  index: number;
  levelMaxSelection: number;
  menuItem: MenuItem;
  typeOrder: number;
  quantityArray: any[];
  globalCount: () => number;
  setQuantityArray: (
    newState: number[] | ((prevState: number[]) => number[])
  ) => void;
  updateSelectedArticles: (
    selecteMenuItem: MenuItem,
    selectedIngredients: SelectedIngredient[],
    eliminatedIngredients: SelectedIngredient[],
    mode: SelectionMode
  ) => void;
}

const MultiSelectionItem = (props: MultiSelectionItemProps) => {
  const {
    index,
    levelMaxSelection,
    menuItem,
    typeOrder,
    quantityArray,
    updateSelectedArticles,
    globalCount,
    setQuantityArray,
  } = props;
  const isChecked = quantityArray[index] > 0;
  const price = findPriceByTypeOrder(menuItem.typeOrderPrices, typeOrder) || 0;
  const [increaseDisabled, setIncreaseDisabled] = useState<boolean>(false);

  useEffect(() => {
    
    const currentGlobalCount = globalCount();
    setIncreaseDisabled( currentGlobalCount === levelMaxSelection || menuItem.isOutOfStock);
  }, [globalCount, levelMaxSelection, quantityArray, index, menuItem.isOutOfStock]);

  const additionalStyles = increaseDisabled
    ? { opacity: 0.5, cursor: "not-allowed" }
    : {};

  const [ingredientModalState, setIngredientModalState] = useState<{
    isOpen: boolean;
    activeIndex: number | null;
  }>({ isOpen: false, activeIndex: null });

  const [ingredientSelections, setIngredientSelections] = useState<
    SelectedIngredient[]
  >([]);

  useEffect(() => {
    if (menuItem?.articleComposition) {
      const initialSelectedIngredients = menuItem?.articleComposition
        ?.filter((ingredient: Ingredient) => ingredient?.inclus)
        ?.map((ingredient: Ingredient) => ({
          id: ingredient?.id,
          ingredient,
          quantity: 1,
        }));

      setIngredientSelections(initialSelectedIngredients);
    }
  }, [menuItem]);

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    menuItem: MenuItem,
    index: number
  ) => {
    if (e.target.checked) {
      updateQuantityArray(quantityArray[index] + 1, index, menuItem);
    } else {
      updateQuantityArray(quantityArray[index] - 1, index, menuItem);
    }
  };

  const updateQuantityArray = (
    newQ: number,
    index: number,
    menuItem: MenuItem
  ) => {
    const aux = Array.from(quantityArray);
    aux[index] = newQ;
    setQuantityArray(aux);

    menuItem.quantity = newQ;

    updateSelectedArticles(
      menuItem,
      ingredientSelections,
      [],
      SelectionMode.MULTIPLE
    );
  };

  const handleOpenIngredientModal = (id: number) => {
    setIngredientModalState({ isOpen: true, activeIndex: id });
  };

  const handleCloseIngredientModal = () => {
    setIngredientModalState({ isOpen: false, activeIndex: null });
  };

  // const [checkedItem, setCheckedItem] = useState<MenuItem>();

  const updateIngredientSelections = (
    selections: SelectedIngredient[],
    eliminatedIngredients: SelectedIngredient[],
    menuItem: MenuItem
  ) => {
    menuItem.quantity = menuItem?.quantity ?? 1;

    setIngredientSelections(selections);

    if (menuItem.maxNbrSelection === 1) {
      if (!isChecked) {
        const e = {
          target: {
            checked: true,
          },
        } as any;
        handleCheckboxChange(e, menuItem, index);
      }
    } else {
      if (quantityArray[index] == 0) {
        updateQuantityArray(quantityArray[index] + 1, index, menuItem);
      }
    }

    updateSelectedArticles(menuItem, selections, eliminatedIngredients, SelectionMode.MULTIPLE);
  };

  return (
    <div className="MultiSelectionItem">
      <div className="row supplement-parent">
        <div className="supplement-wrapper">
          <div className="supplement-row">
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: 600,
                color: `${menuItem.isOutOfStock ? "#999999" : "#1D2939"}`,
              }}
            >
              {menuItem?.name?.toLowerCase()}
            </Typography>

            <Typography className="price">
              {price > 0 && `+${price} €`}
            </Typography>
          </div>

          <div className="row">
            {menuItem.isOutOfStock ? (
              <span className="epuise">Épuisé</span>
            ) : menuItem.maxNbrSelection === 1 ? (
              <Checkbox
                className={`checkboxEl ${(increaseDisabled && !isChecked) ? "disabled" : ""} ${isChecked ? "checked" : ""}`}
                value={quantityArray[index]}
                checked={isChecked}
                disabled={increaseDisabled && !isChecked}
                onChange={(e) => handleCheckboxChange(e, menuItem, index)}
              />
            ) : (
              <QuantityCounter
                index={index}
                menuItem={menuItem}
                maxSelection={menuItem.maxNbrSelection}
                quantityArray={quantityArray}
                updateQuantityArray={updateQuantityArray}
                increaseDisabled={increaseDisabled}
              />
            )}
            {menuItem?.nbrComposition > 0 && (
              <IconButton
                onClick={() => {
                  !increaseDisabled && handleOpenIngredientModal(menuItem.id);
                }}
                style={{ paddingLeft: 0, ...additionalStyles }}
              >
                <div
                  className={`btnArrow ${menuItem.isOutOfStock ? "outOfStock" : ""}`}
                >
                  <ChevronRightRoundedIcon />
                </div>
              </IconButton>
            )}
          </div>
        </div>
      </div>

      {ingredientSelections.length > 0 && (menuItem?.quantity ?? 0) > 0 && (
        <Collapse
          in={true}
          className="all-center tableComposition"
          style={{
            width: "70%",
            // backgroundColor:'pink',
            height: "50%",
          }}
        >
          <Stack
            className="compositionPreview full-width"
            spacing={0.5}
            direction={"column"}
          >
            <Stack direction={"column"} spacing={0.7}>
              {ingredientSelections?.map(
                (selectedIngredient: SelectedIngredient, i: number) => {
                  const { ingredient, quantity } = selectedIngredient;

                  const price = getPriceByTypeOrderForComposition(
                    typeOrder,
                    ingredient
                  );
                  return (
                    <p key={i} className="row tableItem">
                      {quantity > 1 && quantity} {ingredient.name.toLowerCase()}{" "}
                      {price > 0 && ` +${price} €`}
                    </p>
                  );
                }
              )}
            </Stack>

            <Divider />
            <Button
              onClick={() => {
                handleOpenIngredientModal(menuItem.id);
              }}
              variant="text"
              size="small"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <small style={{ color: "#344054" }}>
                Modifier votre sélection{" "}
              </small>
              <ChevronRightRoundedIcon style={{ fill: "#344054" }} />
            </Button>
          </Stack>
        </Collapse>
      )}

      {ingredientModalState?.isOpen && (
        <IngredientModal
          isOpen={ingredientModalState?.isOpen}
          onClose={handleCloseIngredientModal}
          updateIngredientSelections={updateIngredientSelections}
          menuItem={menuItem}
          compositionMaxSelection={menuItem?.compositionMaxSelection}
          ingredients={menuItem?.articleComposition}
          typeOrder={typeOrder}
          ingredientSelections={ingredientSelections}
          isCheckedItem={(menuItem?.quantity ?? 0) > 0}
        />
      )}
    </div>
  );
};

export default MultiSelectionItem;
