import { Autocomplete } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useConfig } from "../../../context/ConfigContext";
import withNavigateHook from "../../../hooks/withNavigateHook";
import AccountLoginWhite from "../../../images/AccountLoginWhite.svg";
import CloseBtn from "../../../images/CloseIcon_btn.svg";
import {
  clearStoreItem,
  logout,
  updateUserLocation,
} from "../../../store/actions/actions";
import { getIcon, IconType } from "../../../theme/icons";
import { isColorDark } from "../../../utils/colorsUtils";
import { truncateString } from "../../../utils/string";
import "./AppNavbar.scss";
import CartModal from "./CartModal/CartModal";
import PanierModal from "./PanierModal/PanierModal";
import ProfilModal from "./ProfilModal/ProfilModal";
import { navigateBasedOnRestaurantCount } from "../../../utils/navigationUtils";

const AppNavbar = (props: any) => {
  const { config } = useConfig();
  const restaurantCount = config?.establishementCount || 0;
  const showSearchBar = config?.showSearchBar || false;
  const logo = isColorDark(config?.layoutColor || "")
    ? config?.appLightLogoImage
    : config?.appDarkLogoImage;
  const [autoComplete, setAutoComplete] =
    useState<google.maps.places.Autocomplete>();
  const [autoCompleteMobile, setautoCompleteMobile] =
    useState<google.maps.places.Autocomplete>();
  const [addressText, setAddressText] = useState<string>();
  const navigate = useNavigate();
  const [showLocation, setShow] = useState(false);
  const [showRecherche, setShowRecherche] = useState(false);
  const [blockBody, setblockBody] = useState(false);
  const token = localStorage.getItem("token");
  const accessToken = localStorage.getItem("accessToken");

  const location = useLocation();
  const isRestaurantsPage =
    location.pathname === "/restaurants" ||
    location.pathname === "/mon-profile" ||
    location.pathname === "/mes-commandes";
  const dontShowPanier =
    location.pathname === "/paiement" ||
    location.pathname === "/apropos" ||
    location.pathname === "/apropos-politique";

  const dontShopLocation =
    location.pathname === "/apropos" ||
    location.pathname === "/apropos-politique" ||
    location.pathname === "/mon-profile" ||
    location.pathname === "/mes-commandes";

  useEffect(() => {
    if (props.location?.shownAddress != undefined) {
      setAddressText(props.location?.shownAddress);
    }
  }, [props.location?.shownAddress]);

  useEffect(() => {
    if (blockBody) {
      document.querySelector("body")?.classList.add("disable-scroll");
    } else {
      document.querySelector("body")?.classList.remove("disable-scroll");
    }
  }, [blockBody]);

  const handelBodyScroll = () => {
    setblockBody((prevblockBody) => !prevblockBody);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      props.updateUserLocation({
        shownAddress: "",
      });
    }
  };

  const resetAddressText = () => {
    setAddressText("");
  };

  const resetSearchText = () => {
    props.setRestorentFilter("");
  };

  const onAutoCompleteLoad = (
    autocomplete: google.maps.places.Autocomplete,
    type: "Mobile" | "Web"
  ) => {
    console.log("autocomplete", { autocomplete });
    type == "Mobile"
      ? setautoCompleteMobile(autocomplete)
      : setAutoComplete(autocomplete);
  };

  const onPlaceChanged = async (type: "Mobile" | "Web") => {
    try {
      if (!autoComplete || !autoCompleteMobile) return;

      let place: any =
        type == "Web" ? autoComplete.getPlace() : autoCompleteMobile.getPlace();
      console.log({ place });
      if (place) {
        console.log({ place });

        let obj = {
          lat: place.geometry?.location.lat() ?? null,
          lng: place.geometry?.location.lng() ?? null,
        };

        props.updateUserLocation({
          ...obj,
          shownAddress: place.formatted_address ?? "",
        });

        if (!place.formatted_address) {
          return;
        }

        if (
          place.formatted_address &&
          props.location.pathname !== "/restaurants"
        ) {
          navigateBasedOnRestaurantCount(restaurantCount, navigate);
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div className="Nav">
      <div
        id="header-restaurant"
        style={{
          height: "70px",
          display: "flex",
        }}
      >
        <nav className="nav-bar" style={{ height: "70px", display: "flex" }}>
          <div
            className="nav-wrapper"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              //   margin: "auto",
              //   maxWidth: "1440px",
            }}
          >
            <Link
              style={{ display: "flex" }}
              to="/"
              onClick={(e) => e.stopPropagation()}
            >
              <img
                className="logo-header"
                src={logo}
                alt="app-logo"
                onClick={() => {
                  // openCart();
                  navigate("/");
                  // handleClose();
                }}
              />
            </Link>

            <div className="search-container">
              <div
                className="search-bar dekstop-search"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    display: !showSearchBar ? "none" : undefined,
                  }}
                  className="search-bar-field"
                >
                  {dontShopLocation ? null : (
                    <div className="search-bar-wrapper">
                      <svg
                        className="locationIcon"
                        width="16"
                        height="20"
                        viewBox="0 0 16 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.63981 10.8337C9.02052 10.8337 10.1398 9.71437 10.1398 8.33366C10.1398 6.95295 9.02052 5.83366 7.63981 5.83366C6.2591 5.83366 5.13981 6.95295 5.13981 8.33366C5.13981 9.71437 6.2591 10.8337 7.63981 10.8337Z"
                          stroke="black"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.63981 18.3337C10.9731 15.0003 14.3065 12.0156 14.3065 8.33366C14.3065 4.65176 11.3217 1.66699 7.63981 1.66699C3.95791 1.66699 0.973145 4.65176 0.973145 8.33366C0.973145 12.0156 4.30648 15.0003 7.63981 18.3337Z"
                          stroke="black"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <Autocomplete
                        className="dropdown"
                        restrictions={{ country: "fr" }}
                        onPlaceChanged={() => onPlaceChanged("Web")}
                        onLoad={(autoComplete) =>
                          onAutoCompleteLoad(autoComplete, "Web")
                        }
                      >
                        <input
                          onKeyDown={handleKeyDown}
                          onChange={(evt) => setAddressText(evt.target.value)}
                          placeholder="Saisissez une adresse"
                          type="text"
                          value={truncateString(addressText ?? "", 32)}
                        />
                      </Autocomplete>
                      {addressText && addressText?.length > 0 && (
                        <div className="clear-inp">
                          <img
                            src={CloseBtn}
                            alt=""
                            onClick={resetAddressText}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {/* {props.locationPathName === "/mes-commandes" ? null : (
                  <div className=" search-bar-rest">
                    <div className="search-bar-wrapper">
                      <svg
                        className="searchIcon"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.75075 15.7222C12.6781 15.7222 15.8619 12.5385 15.8619 8.6111C15.8619 4.68375 12.6781 1.5 8.75075 1.5C4.8234 1.5 1.63965 4.68375 1.63965 8.6111C1.63965 12.5385 4.8234 15.7222 8.75075 15.7222Z"
                          stroke="#18181B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17.6391 17.4995L13.7725 13.6328"
                          stroke="#18181B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <input
                        placeholder="Recherche"
                        type="text"
                        value={props.RestorentFilter ?? ""}
                        onChange={(e) =>
                          props.setRestorentFilter(e.target.value)
                        }
                        style={{}}
                      />
                      {props.RestorentFilter?.length > 0 && (
                        <div className="clear-inp">
                          <img
                            src={CloseBtn}
                            alt=""
                            onClick={resetSearchText}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )} */}
              </div>

              {dontShopLocation ? null : (
                <div
                  className="search-bar mobile-search"
                  style={{ display: !showSearchBar ? "none" : undefined }}
                >
                  <div
                    style={{ position: "relative" }}
                    className="search-bar-field"
                  >
                    <div className="search-bar-wrapper">
                      {/* svg */}
                      <div className="img-box" onClick={handelBodyScroll}>
                        <div onClick={() => setShow(!showLocation)}>
                          {getIcon(IconType.LOCATION, {
                            color: config?.primaryColor,
                          })}
                        </div>
                      </div>

                      <div
                        className={`inp-full-width ShowSearchModal  ${
                          showLocation ? "show_inp" : ""
                        }`}
                      >
                        <div className="ModalBox">
                          <label>Adresse </label>
                          <div className="CloseBtn">
                            <img
                              src={CloseBtn}
                              alt=""
                              onClick={() => setShow(!showLocation)}
                            />
                          </div>
                          <div className="SearchBox">
                            <div className="inp-icon">
                              {getIcon(IconType.LOCATION, {
                                color: config?.primaryColor,
                              })}
                            </div>
                            <Autocomplete
                              restrictions={{ country: "fr" }}
                              onPlaceChanged={() => onPlaceChanged("Mobile")}
                              onLoad={(autoComplete) =>
                                onAutoCompleteLoad(autoComplete, "Mobile")
                              }
                            >
                              <input
                                onKeyDown={handleKeyDown}
                                onChange={(evt) =>
                                  setAddressText(evt.target.value)
                                }
                                placeholder="Votre adresse"
                                type="text"
                                value={addressText}
                              />
                            </Autocomplete>
                          </div>
                          <button
                            className="save-btn"
                            onClick={() => setShow(false)}
                          >
                            Enregistrer
                          </button>
                        </div>
                        {/* <span
                        className="clear-inp"
                        onClick={() => setShow(!showLocation)}
                      >
                        x
                      </span> */}

                        <div
                          style={{ backgroundColor: "#0000009c" }}
                          className="overlay"
                          onClick={() => {
                            setShow(!showLocation);
                            handelBodyScroll();
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div className=" search-bar-rest">
                    <div className="search-bar-wrapper">
                      <div className="img-box" onClick={handelBodyScroll}>
                        {getIcon(IconType.SEARCH, {
                          color: config?.primaryColor,
                          onClick: () => setShowRecherche(!showRecherche),
                        })}
                      </div>
                      <div
                        className={`inp-full-width ShowSearchModal ${
                          showRecherche ? "show_inp" : ""
                        }`}
                      >
                        <div className="ModalBox">
                          <label>Recherche </label>
                          <div className="CloseBtn">
                            <img
                              src={CloseBtn}
                              alt=""
                              onClick={() => setShowRecherche(!showRecherche)}
                            />
                          </div>
                          <div className="SearchBox">
                            {getIcon(IconType.SEARCH, {
                              color: config?.primaryColor,
                              className: "inp-icon",
                            })}

                            <input
                              placeholder="Recherche"
                              type="text"
                              value={props.RestorentFilter ?? ""}
                              onChange={(e) =>
                                props.setRestorentFilter(e.target.value)
                              }
                            />
                          </div>
                          <button
                            className="save-btn"
                            onClick={() => setShowRecherche(false)}
                          >
                            Enregistrer
                          </button>
                        </div>

                        <div
                          style={{ backgroundColor: "#0000009c" }}
                          className="overlay"
                          onClick={() => {
                            setShowRecherche(!showRecherche);
                            handelBodyScroll();
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="user-container">
                {!token && !accessToken ? (
                  <button
                    className="btn-insc"
                    onClick={() => {
                      // openCart();
                      navigate("/Authentification");
                      // handleClose();
                    }}
                  >
                    <p>Se connecter</p>
                    <img src={AccountLoginWhite} alt="" />
                  </button>
                ) : (
                  ""
                )}
                {accessToken ? (
                  <div className="user-profile">
                    <ProfilModal />
                  </div>
                ) : (
                  ""
                )}
                {!dontShowPanier &&
                  (isRestaurantsPage ? <PanierModal /> : <CartModal />)}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    homeLoading: state.shop.homeLoading,
    shops: state.shop.shops,
    shopMode: state.shop.mode,
    currentCategory: state.category.currentCategory,
    restaurantNameFilter: state.shop.restaurantNameFilter,
    cartList: state.order.cartList,
    cartListById: state.order.cartListById,
    savedCategories: state.cart.savedCategories,
    userInfo: state.auth.userInfo,
    sendVerificationCodeResponse: state.auth.sendVerificationCodeResponse,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch(logout()),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),

    updateUserLocation: (location: any) =>
      dispatch(updateUserLocation(location)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(AppNavbar));
