import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import AppLoading from "../components/shared/Loaders/HomeLoading/AppLoading";
import { ApiResponse } from "../interfaces/ApiResponse";
import { loadConfig } from "../services/apiConfig";
import axios from "axios";

export interface GraphicChart {
  id: number;
  companyId: number;
  primaryColor: string;
  secondaryColor: string;
  layoutColor: string;
  appLogoImage: string;
  appDarkLogoImage: string;
  appLightLogoImage: string;
  backgroundDesktopImage: string;
  backgroundTabletImage: string;
  backgroundMobileImage: string;
  establishementCount: number;
  establishementId: number | null;
  withCategoryWheel: boolean;
  hasDarkBackground: boolean;
  showSearchBar: boolean;
}

interface ConfigContextType {
  config: GraphicChart | null;
  setConfig: React.Dispatch<React.SetStateAction<GraphicChart | null>>;
}

export const ConfigContext = createContext<ConfigContextType | undefined>(
  undefined
);

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};

const initialConfig: GraphicChart = {
  id: 0,
  companyId: 0,
  primaryColor: "#E05F03",
  secondaryColor: "black",
  layoutColor: "grey",
  appLogoImage: "",
  appDarkLogoImage: "",
  appLightLogoImage: "",
  backgroundDesktopImage: "",
  backgroundTabletImage: "",
  backgroundMobileImage: "",
  establishementCount: 0,
  establishementId: 0,
  withCategoryWheel: false,
  hasDarkBackground: false,
  showSearchBar: false,
};

export const ConfigProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [config, setConfig] = useState<GraphicChart | null>(initialConfig);
  const [isLoading, setIsLoading] = useState(true);

  const fetchGraphicChart = async (apiCatalogue: string, companyId: string) => {
    try {
      const url = `${apiCatalogue}/ClickAndCollectGraphicChart/GetClickAndCollectGraphicChart?CompanyId=${companyId}`;
      const response = await axios.get(url);
      const data: ApiResponse<GraphicChart> = response.data;
      const graphicChart = data.data.result;
      return graphicChart;
    } catch (error) {
      console.error("Error fetching the graphic chart:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await loadConfig();
        localStorage.removeItem("establishementToken");
        localStorage.setItem("companyId", config.companyId);
        localStorage.setItem("api_baseUrl", config.api_baseUrl || "");
        localStorage.setItem("api_catalogue", config.api_catalogue || "");
        localStorage.setItem("key_google", config.key_google || "");
        localStorage.setItem(
          "stripe_public_key",
          config.stripe_public_key || ""
        );
        localStorage.setItem(
          "mapObject",
          JSON.stringify(config.mapObject || {})
        );

        const graphicChart = await fetchGraphicChart(
          config.api_catalogue,
          config.companyId
        );

        setConfig({
          ...graphicChart,
          withCategoryWheel: config.withCategoryWheel,
          hasDarkBackground: config.hasDarkBackground,
          showSearchBar: config.showSearchBar,
        });

        document.documentElement.style.setProperty(
          "--primary-color",
          graphicChart.primaryColor
        );
        document.documentElement.style.setProperty(
          "--secondary-color",
          graphicChart.secondaryColor
        );
        document.documentElement.style.setProperty(
          "--layout-color",
          graphicChart.layoutColor
        );
      } catch (error) {
        console.error("Error loading configuration or colors:", error);

        document.documentElement.style.setProperty(
          "--primary-color",
          initialConfig.primaryColor
        );
        document.documentElement.style.setProperty(
          "--secondary-color",
          initialConfig.secondaryColor
        );
        document.documentElement.style.setProperty(
          "--layout-color",
          initialConfig.layoutColor
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfig();
  }, []);

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <ConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};
