import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeCardNumberElement } from "@stripe/stripe-js";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { languageData } from "../../i18n";
import ErrorIcon from "../../images/ErrorIcon.svg";
import { RestaurantCart } from "../../interfaces/Catalogue";
import {
  mapToCreateDeliveryRequest,
  mapToOrderPayload,
  mapToOrderSummary,
} from "../../mapper/orderPayloadMapper";
import { orderApi, paymentApi } from "../../services/apiGate";
import {
  clearStoreItem,
  confirmPaymentAction,
  createPaymentAction,
  updateSavedCart,
} from "../../store/actions/actions";
import { getTotalOrder } from "../../utils";
import { MODE_DELIVERY, MODE_TAKEAWAY } from "../../utils/deliveryUtils";
import { selectedTimeRightISO } from "../../utils/dateUtils";

interface CheckoutFormProps {
  restaurantCart: RestaurantCart | undefined;
  isOnlinePaymentChecked: boolean;
  setqrcode: (str: string) => void;
  setcommand: (command: any) => void;
  setpromptCommand: (value: boolean) => void;
  setShowProcessingCommand: () => void;
  isValidTime: boolean;
  isMaintenantChecked: boolean;
  selectedTimeRight: string;
  quoteId: string;
  estimatedDeliveryQuoteMinutes: number;
  fees: number;
  [key: string]: any;
}

function CheckoutForm(props: CheckoutFormProps) {
  const {
    restaurantCart,
    isOnlinePaymentChecked,
    quoteId,
    estimatedDeliveryQuoteMinutes,
    fees,
  } = props;
  const stripe = useStripe();

  const deliveryAddress: google.maps.places.PlaceResult | undefined =
    useSelector((state: any) => state.delivery.deliveryAddress);
  const [error, seterror] = useState("");
  const [loading, setloading] = useState(false);
  const elements = useElements();

  useEffect(() => {
    seterror("");
  }, [props.isOnlinePaymentChecked]);

  useEffect(() => {
    try {
      if (props.payementSuccess === true) {
        window.scrollTo(0, 0);
        document.querySelector(".paimentSection")?.classList.add("slideUpHide");
        document
          .querySelector(".InvoiceCommandComponent")
          ?.classList.add("DisplayInvoice");
        props.setShowProcessingCommand();
        props.setpromptCommand(true);
        props.setqrcode(props.payementResponse?.QrCode);

        setTimeout(() => {
          setTimeout(() => {
            document
              .querySelector(".InvoiceCommandComponent")
              ?.classList.add("DisplayInvoice");
            fetchOrderDetails("" + props.payementResponse?.commandId);
          }, 1000);

          //window.location.href = "#/mes-commandes";
        }, 6000);
        //  navigate('PaymentSuccess', {order: {...order}}, false);

        props.clearItemStore("payementSuccess");
        props.clearItemStore("payementResponse");
      } else if (props.payementSuccess === false) {
        if (props.payementResponse?.status == "requires_action")
          confirmpay(
            props.payementResponse?.clientSecret,
            props.payementResponse?.commandId
          );
        else {
          console.log("props.payementResponse", props.payementResponse);
          if (props.payementResponse?.error) {
            setloading(false);
            seterror("Echec de paiement. Veuillez réessayer plus tard.");
            /* navigate(
              "PaymentError",
              { order: { ...order }, error: props.payementResponse?.error },
              false
            ); */
          }
        }

        //
      } else {
        setloading(false);
      }
    } catch (error) {}
    return () => {
      props.clearItemStore("payementSuccess");
    };
  }, [props.payementSuccess, props.payRetries]);

  const ___handleCreatePayment = async (payId: any, payLater?: boolean) => {
    if (restaurantCart) {
      try {
        const PrepareFor = props.isMaintenantChecked
          ? selectedTimeRightISO(props.selectedTimeRight)
          : null;

        const createDeliveryRequest = mapToCreateDeliveryRequest(
          deliveryAddress,
          quoteId,
          props.userInfo,
          estimatedDeliveryQuoteMinutes
        );

        const completeAddress = `${createDeliveryRequest?.clientAddress?.streetAddress}, ${createDeliveryRequest?.clientAddress?.city}, ${createDeliveryRequest?.clientAddress?.state}, ${createDeliveryRequest.clientAddress?.zip_code}, ${createDeliveryRequest?.clientAddress?.country}`;

        const totalOrder = getTotalOrder(restaurantCart.categories);
        const orderSummary = mapToOrderSummary(restaurantCart.categories);

        const orderPayload = mapToOrderPayload(
          restaurantCart,
          totalOrder,
          isOnlinePaymentChecked,
          props.isMaintenantChecked,
          PrepareFor,
          props.userInfo,
          createDeliveryRequest,
          completeAddress
        );

        const orderDetailed = restaurantCart.categories;

        const createPaymentBody = {
          firstName: props.userInfo.firstName,
          lastName: props.userInfo.lastName,
          email: props.userInfo.email,
          phone: props.userInfo.phoneNumber,
          restaurantId: restaurantCart.restaurant.id,
          orderSummary,
          orderDetailed: JSON.stringify(orderDetailed),
          command: JSON.stringify(orderPayload),
          remise: "null",
          payLater: payLater ? true : false,
          address: completeAddress || "",
          idempotencyKey: "" + uuidv4(),
          loyaltySecretKey: "",
          codePromo: "",
          paymentMethodId: "" + payId,
          diningMode:
            restaurantCart.mode == MODE_TAKEAWAY
              ? "takeaway"
              : restaurantCart.mode == MODE_DELIVERY
                ? "delivery"
                : "onspot",
          PrepareFor: PrepareFor,
          qrCode: "",
          isDelivery: restaurantCart.mode == MODE_DELIVERY,
          ...(restaurantCart.mode == MODE_DELIVERY && {
            createDeliveryRequest,
          }),
        };

        console.log("createPaymentBody", createPaymentBody);

        props.createPayment(createPaymentBody);
      } catch (error) {
        console.error("🚀 ~ ___handleCreatePayment= ~ error:", error);
        setloading(false);
      }
    }
  };

  const handleSubmit = async (event: any) => {
    seterror("");

    if (fees == 0 && restaurantCart?.mode == MODE_DELIVERY) {
      seterror("Problème de livraison. Veuillez réessayer plus tard.");
      return;
    }

    if (props.isOnlinePaymentChecked) {
      const cardElementt: StripeCardNumberElement | null | undefined =
        elements?.getElement(CardNumberElement);
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      if (cardElementt) {
        window.scrollTo(0, 0);
        stripe
          .createPaymentMethod({
            type: "card",
            card: cardElementt,
          })
          .then(function (result) {
            if (!result.error) {
              setloading(true);
              ___handleCreatePayment(result.paymentMethod?.id);
            }
            if (result.error) {
              setloading(false);
              seterror("Votre numéro de carte est incomplet.");
            }
          })
          .catch((error) => {
            console.log("🚀 ~ handleSubmit ~ error:", error);
            setloading(false);
            console.log(error);
          });
      }
    } else {
      setloading(true);
      ___handleCreatePayment(null, true);
    }
  };

  const confirmpay = async (sc: string, commandId: number) => {
    try {
      const resposne = await stripe?.confirmCardPayment(sc);

      let paymentIntent = resposne?.paymentIntent;
      if (paymentIntent)
        if (paymentIntent) {
          // console.log(paymentIntent?.status);
          let response = await paymentApi.confirmPayment({
            commandId: commandId,
            paymentIntentId: paymentIntent?.id,
            idempotencyKey: uuidv4(),
            codePromo: null,
            status: paymentIntent?.status ?? "canceled",
          });
          window.scrollTo(0, 0);
          document
            .querySelector(".paimentSection")
            ?.classList.add("slideUpHide");
          props.setShowProcessingCommand();
          setTimeout(() => {
            window.location.href = "#/mes-commandes";
          }, 6000);
        }
    } catch (error) {
      console.error("🚀 ~ confirmpay ~ error:", error);
      setloading(false);
      seterror("Echec de paiement");
    }
  };

  const fetchOrderDetails = async (id: string) => {
    try {
      let response = await orderApi.getOrderById(id);
      let orderDetails = response.data;
      if (orderDetails) {
        props.setcommand(orderDetails?.commande);
      }
    } catch (error) {
      console.error("🚀 ~ fetchOrderDetails ~ error:", error);
    }
  };

  return (
    <div className="cardContainer">
      <div className={loading ? "OverylayLoading" : ""}></div>
      {props.isOnlinePaymentChecked && (
        <div>
          <div className="card-number card-info">
            <CardNumberElement options={{ showIcon: true }}></CardNumberElement>
          </div>
          <div style={{ display: "flex", gap: "20px" }} className="">
            <div className="card-info" style={{ flex: "1" }}>
              <CardExpiryElement></CardExpiryElement>
            </div>
            <div className="card-info" style={{ flex: "1" }}>
              <CardCvcElement></CardCvcElement>
            </div>
          </div>
          {error && (
            <p className="msg_error">
              <img src={ErrorIcon} alt="" />
              {error}
            </p>
          )}
        </div>
      )}

      {!props.isOnlinePaymentChecked && error && (
        <p className="msg_error">
          <img src={ErrorIcon} alt="" />
          {error}
        </p>
      )}

      <div className="submit-order">
        {loading ? (
          <div className="BtnLoader">
            <div className="disabledClass"></div>
            <button onClick={handleSubmit}>
              <p>Chargement</p>
              <div style={{ transform: "translateX(30px)" }}>
                <div className="loaderBtn"></div>
              </div>
            </button>
          </div>
        ) : (
          <span>
            <button disabled={!props.isValidTime} onClick={handleSubmit}>
              <p>{languageData.fr.payment.btnText}</p>
            </button>
          </span>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    selectedProductWithComposition: state.menu.selectedProductWithComposition,
    selectedArticle: state.menu.selectedArticle,
    menuId: state.menu.menuId,
    currentPrice: state.cart.currentPrice,
    menu: state.menu.menu,
    preSavedCart: state.cart.preSavedCart,
    productHasCompositionId: state.menu.productHasCompositionId,
    componentId: state.menu.componentId,
    compositionsPrice: state.cart.compositionsPrice,
    selectedOrder: state.order.selectedOrder,
    userInfo: state.auth.userInfo,
    savedCategories: state.cart.savedCategories,
    location: state.auth.location,
    payementSuccess: state.payment.payementSuccess,
    payRetries: state.payment.payRetries,
    payementResponse: state.payment.payementResponse,
    categoryShopMode: state.category.categoryShopMode,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    confirmPayment: (order: any) => dispatch(confirmPaymentAction(order)),
    createPayment: (order: any) => dispatch(createPaymentAction(order)),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
    updateSavedCart: (obj: any) => dispatch(updateSavedCart(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
