import { useEffect, useState } from "react";
import { languageData } from "../../../../i18n";
import CloseIcon from "../../../../images/CloseIcon_btn.svg";
import AdressInput from "../../AdressInput/AdressInput";
import "./AddressSelectionModal.scss";

interface AddressSelectionModalProps {
  showModal: boolean;
  restaurantId: string;
  hideModal: () => void;
  onConfirmAddress: (address: google.maps.places.PlaceResult | undefined) => void;
}

const AddressSelectionModal = (props: AddressSelectionModalProps) => {
  const { showModal, restaurantId, hideModal, onConfirmAddress } = props;
  const [address, setAddress] = useState<google.maps.places.PlaceResult | undefined>();
  // const [showConfirm, setShowConfirm] = useState(false);

  const modalClass = showModal ? "modal show" : "modal";

  const handleAddressChange = (deliveryAddress: google.maps.places.PlaceResult | undefined) => {
    setAddress(deliveryAddress);
  };

  const handleConfirm = () => {
      if(address){
        onConfirmAddress(address);
      }
      hideModal();
  };

  const AddClassToBody = () => {
    const body = document.querySelector('body');
    if (showModal) {
      body?.classList.add("ModalEnabled");
    } else {
      body?.classList.remove("ModalEnabled");
    }
  };

  useEffect(() => {
    AddClassToBody()
  }, [showModal]);

  return (
    <div className={modalClass}>
      <div className="modal-overlay" onClick={hideModal}></div>

      <div className="modal-content">
        <button className="close-btn" onClick={hideModal}>
          <img src={CloseIcon} alt="Close" />
        </button>
        <h2>{languageData.fr.delivery.selectAddress}</h2>

        {/* <div className="address-selection"> */}
        <AdressInput onAdressChange={handleAddressChange} />

        {/* Confirm button */}
        <button
          className={`confirm-btn ${!address ? "btn-disabled" : ""}`}
          onClick={handleConfirm}
          disabled={!address}
        >
          {languageData.fr.delivery.confirm}
        </button>
      </div>
    </div>
  );
};

export default AddressSelectionModal;
