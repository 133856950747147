export interface TypeOrderFamily {
  id: number;
  designation: string;
  type: number;
}

export interface Category {
  id: number;
  orderIndex: number;
  designation: string;
  visible: boolean;
  idImageUpload: number | null;
  description: string | null;
  colorBorne: string;
  backColorBorne: string;
  showName: boolean;
  categoryMenusCount: number;
  categoryArticlesCount: number;
  idBadge: number | null;
  imageUrl: string | null;
  typeOrderFamily: TypeOrderFamily[];
}

export interface TypeOrderPrice {
  id: number;
  designation: string;
  type: number;
  price?: number;
}

export interface CategoryItem {
  id: number;
  idRef: number;
  orderIndex: number;
  designation: string;
  description: string | null;
  imgUrl: string | null;
  backColor: string | null;
  foreColor: string | null;
  isMenu: boolean;
  categoryId: number;
  kitchenNote: string | null;
  tvaDelivery: number | null;
  tvaEmporter: number | null;
  tvaOnSite: number | null;
  hasComposition: boolean | null;
  isOutOfStock: boolean;
  hasAllergene: boolean | null;
  articleAllergen: Allergen[];
  kitchenBckColorTotalQte: string | null;
  maxNbrSelectionComposition: number | null;
  screens: Screen[];
  displayTotalQteToPrepare: boolean | null;
  kitchenFrColor: string | null;
  visible: boolean;
  showName: boolean;
  menuSubMenuCount: number;
  menuLevelsCount: number;
  typeOrderCategory: TypeOrderPrice[];
  order?: OrderItem[],
  selectedIngredients?: SelectedIngredient[],
  eliminatedIngredients?: SelectedIngredient[],
  quantity?: number,
  unitPrice?: number,
  finalUnitPrice?: number,
  // totalPrice?: number,
  familyId: number,
  subFamilyId: number,
  familyName: string,
  subFamilyName: string,
  presentationMenu: boolean
}

export interface TVA {
  id: number;
  taux: number;
}

export interface TypeOrderLevel {
  id: number;
  typeOrderId: number;
  designation: string;
  type: number;
  price: number;
}

export interface Screen {
  id: number
  designation: string
  canShowNotReadyOrder: any
  canShowReadyOrder: any
  canShowAnticipateOrder: boolean
  activateDispatching: boolean
  showNotReadyGroup: boolean
  showReadyGroup: boolean
}

export interface ArticleComposition {
  id: number
  isOutOfStock: boolean
  idArticle: number
  idComposition: number
  name: string
  inclus: boolean
  isActif: any
  maxNbrSelection: number
  priceDelivery: number
  priceEmporter: number
  priceOnSite: number
  orderIndex: number
  tvaDelivery: TVA
  tvaEmporter: TVA
  tvaOnSite: TVA
  imageUrl: any
}

export interface Level {
  id: number;
  name: string;
  orderIndex: number;
  typeOrderLevel: TypeOrderLevel[];
  niveauMaxSelection: number;
  visible: boolean;
  niveauMinSelection: number;
}

export interface MenuItem {
  id: number;
  levelArticleId: number;
  name: string;
  imageUrl: string | null;
  idCategory: number;
  idMenu: number;
  levelId: number;
  orderIndex: number;
  later: boolean;
  isOutOfStock: boolean;
  maxNbrSelection: number;
  nbrComposition: number;
  backColor: string | null;
  frontColor: string | null;
  kitchenColor: string | null;
  kitchenQteColor: string | null;
  kitchenNote: string | null;
  isNotImportant: boolean;
  nbrAllergen: number;
  displaySecondScreen: boolean;
  displayCaisseImage: boolean;
  displayTotalQteToPrepare: boolean;
  typeOrderPrices: TypeOrderPrice[];
  screens: Screen[];
  compositionMaxSelection: number;
  visible: boolean;
  tvaDelivery: TVA;
  tvaEmporter: TVA;
  tvaOnSite: TVA;
  articleComposition: ArticleComposition[];
  articleAllergen: any[]; // Replace with specific type if known
  level: Level;
  quantity?: number,

  familyId: number,
  familyName: string,
  subFamilyId: number,
  subFamilyName: string,
  levelName?: string,
}

export type GroupedArticleByLevel = {
  id: number;
  name: string;
  orderIndex: number;
  typeOrderLevel: TypeOrderLevel[];
  niveauMaxSelection: number;
  niveauMinSelection: number;
  visible: boolean;
  menuItems?: MenuItem[];
};

export interface Allergen {
  id: number
  idArticle: number
  idAllergen: number
  name: string
  description: any
  imageUrl: string
}


export interface Ingredient {
  id: number
  idArticle: number
  // idRef?: number
  idComposition: number
  name: string
  inclus: boolean
  isOutOfStock: boolean
  defaultHidden?: boolean
  isActif: boolean
  maxNbrSelection: number
  priceDelivery: number
  priceEmporter: number
  priceOnSite: number
  orderIndex: number
  tvaDelivery: TVA
  tvaEmporter: TVA
  tvaOnSite: TVA
  imageUrl: string | null
  allergen?: Allergen[]
}

export interface SelectedIngredient {
  id: number;
  ingredient: Ingredient,
  quantity: number
}

export interface SelectedArticle {
  selecteMenuItem: MenuItem;
  selectedIngredients: SelectedIngredient[];
  eliminatedIngredients: SelectedIngredient[];
}

export interface OrderItem {
  level: GroupedArticleByLevel;
  selectedArticles: SelectedArticle[];
}

export enum SelectionMode {
  SINGLE = "Single",
  MULTIPLE = "Multiple"
}

export enum CATEGORY_ITEM_TYPE {
  MENU = "menu",
  ARTICLE = "article"
}

export interface Restaurant {
  isEnabled: boolean,
  nom: string;
  imageUrl: string;
  shifts: serviceMenuModels.Shift[];
  longitude: number;
  latitude: number;
  note: number;
  description: any;
  specialites: any;
  isFavorite: boolean;
  isOnSpotPaymentActive?: boolean;
  isStripeActive?: boolean;
  isOpen: boolean;
  tempsPrep: number;
  address: string;
  menus: serviceMenuModels.Menu[];
  id: number;
  aEmporte: boolean;
  surPlace: boolean;
  livraison?: boolean;
  idMachine: number;
  idPv: number;
  idUser: number;
  idRestau: number;
  azureUrl: string;
  distance?: string
  prepareRestaurantDuration?:string;
  deliveryRestaurantDuration?:string;
  cartThreshold?:number;
}

export interface RestaurantCart {
  restaurant: Restaurant;
  categories: CategoryItem[];
  mode: number;
  menuId: number;
}

export interface Cart {
  savedCategories: Record<number, RestaurantCart>
}

export interface OrderSummary {
  item: string
  quantity: number
  unitPrice: number
  amount: number
}
