import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "../../components/PaymentPage/CheckoutForm";
import InvoiceCommand from "../../components/PaymentPage/InvoiceCommand";
import ProcessingCommand from "../../components/PaymentPage/ProcessingCommand";
import AppNavbar from "../../components/shared/AppNavbar/AppNavbar";
import LeftArrow from "../../images/arrow-left_grey.svg";
import clockIcon from "../../images/time-outline.svg";
import { Restaurant, RestaurantCart } from "../../interfaces/Catalogue";
import { DeliveryQuote } from "../../interfaces/delivery";
import { deliveryApi } from "../../services/deliveryApi";
import {
  clearStoreItem,
  getmyprofile,
  pushCategory,
} from "../../store/actions/actions";
import { getOrderTypeText } from "../../utils";
import {
  calculateTotalDuration,
  centimesToEuros,
  convertTimeToMinutes,
  getDiningModeLabel,
  MODE_DELIVERY,
} from "../../utils/deliveryUtils";
import "./PaymentPage.scss";
import { selectedTimeRightISO } from "../../utils/dateUtils";

const PaymentPage = (props: any) => {
  const restaurantId = localStorage.getItem("restaurantId");
  const deliveryAddress = useSelector(
    (state: any) => state.delivery.deliveryAddress
  );
  const userInfo = useSelector((state: any) => state.auth.userInfo);
  const [selectedTimeRight, setSelectedTimeRight] = useState("");
  const [deliveryQuote, setDeliveryQuote] = useState<DeliveryQuote>();
  const [restaurantCart, setRestaurantCart] = useState<RestaurantCart>();
  const [tempsPreparation, setTempsPreparation] = useState<
    string | undefined
  >();
  const isDeliveryMode = restaurantCart?.mode === MODE_DELIVERY;
  const deliveryDuration =
    restaurantCart?.restaurant?.deliveryRestaurantDuration;
  const prepareDuration = restaurantCart?.restaurant?.prepareRestaurantDuration;

  const [showMarker, setshowMarker] = useState(false);
  const [isOnlinePaymentChecked, setOnlinePaymentChecked] = useState(false);
  const [isMaintenantChecked, setIsMaintenantChecked] = useState(false);
  const [showProcessingCommand, setShowProcessingCommand] = useState(false);
  const [isValidTime, setIsValidTime] = useState(false);
  const [rerender, setRerender] = useState(false);

  const stripPublicKey = localStorage.getItem("stripe_public_key") || "";
  const stripePromise = loadStripe(stripPublicKey);
  const [promptCommand, setpromptCommand] = useState(false);
  const [command, setcommand] = useState();
  const [supTotal, setSupTotal] = useState(0.0);
  const [fees, setFees] = useState(0.0);
  const [qrcode, setqrcode] = useState("");
  const navigate = useNavigate();

  let min = restaurantCart?.restaurant?.shifts[0]?.open || "00:00";
  let max = restaurantCart?.restaurant?.shifts[0]?.close || "00:00";

  let prepdate = restaurantCart?.restaurant?.tempsPrep || 0;
  const todayDate = dayjs().format("YYYY-MM-DD");
  const combinedDateTime = dayjs(`${todayDate}T${min}`).add(prepdate, "minute");
  const [selectedTimee, setSelectedTimee] = useState(combinedDateTime);
  const currentDateTime = dayjs();
  const selectedDateTime = dayjs(`${todayDate}T${selectedTimeRight}`);
  const OpenTime = dayjs(`${todayDate}T${min}`);
  const ClosedTime = dayjs(`${todayDate}T${max}`);
  const combinedDateTime2 = dayjs(`${todayDate}T${max}`).subtract(
    prepdate,
    "minute"
  );

  let value;

  if (isValidTime) {
    value = combinedDateTime;
  } else {
    value = combinedDateTime2;
  }

  if (
    currentDateTime.isAfter(selectedDateTime) ||
    selectedDateTime.format("HH:mm") <
      currentDateTime.add(prepdate, "minute").format("HH:mm")
  ) {
    value = currentDateTime.add(prepdate, "minute");
  } else {
    value = selectedTimee;
    if (selectedTimee.format("HH:mm") > combinedDateTime2.format("HH:mm")) {
      console.log("true is biger ");
      value = currentDateTime.add(prepdate, "minute");
    } else {
      console.log("false is less ");
      value = selectedTimee;
    }
  }

  const center = {
    lat: props.location?.lat,
    lng: props.location?.lng,
  };

  const style = {
    height: "10vh",
    width: "100%",
  };

  useEffect(() => {
    if (!isValidTime) {
      setIsValidTime(true);
    }

    const currentDateTime = dayjs();
    const selectedDateTime = dayjs(`${todayDate}T${selectedTimeRight}`);

    if (currentDateTime.isAfter(selectedDateTime)) {
      setSelectedTimeRight(currentDateTime.format("HH:mm"));
    } else {
      console.log("Current time is after or equal to the selected time.");
    }
  }, [selectedTimeRight, isValidTime, todayDate]);

  useEffect(() => {
    setShowProcessingCommand(false);
  }, []);

  useEffect(() => {
    if (restaurantCart?.mode === MODE_DELIVERY) {
      setOnlinePaymentChecked(true);
      checkRestaurantDelivery(restaurantCart.restaurant);
    }
  }, [restaurantCart]);

  useEffect(() => {
    if (restaurantId !== null && props.savedCategories) {
      const data: RestaurantCart = props.savedCategories[restaurantId];

      const calculatedTotal =
        data?.categories?.length > 0
          ? data.categories?.reduce((total: number, category) => {
              return (
                total +
                (category?.finalUnitPrice || 0) * (category?.quantity || 0)
              );
            }, 0)
          : 0;

      setFees(0);
      setSupTotal(parseFloat(calculatedTotal.toFixed(2)));

      setRestaurantCart(data);
      if (!data?.restaurant?.isOnSpotPaymentActive) {
        setOnlinePaymentChecked(true);
      }
    }
  }, []);

  useEffect(() => {
    setRerender((prev) => !prev);
  }, [restaurantId]);

  useEffect(() => {
    props.getmyprofile();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("redirectedToAuth")) {
      localStorage.setItem("redirectedToAuth", "false");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setshowMarker(true);
    }, 2000);
  }, []);

  const handlePaymentMethodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOnlinePaymentChecked(event.target.value === "online");
  };

  const handlePlanificationMethodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsMaintenantChecked(event.target.value === "planifier");
  };

  const removeRestaurantFromSavedCategories = () => {
    let savedCategories = props.savedCategories;
    delete savedCategories[restaurantId as string];

    props.pushCategory(savedCategories);
  };

  const handleTimeChange = (newValue: any) => {
    const selectedHours = newValue.hour();
    const selectedMinutes = newValue.minute();
    const selectedTotalMinutes = selectedHours * 60 + selectedMinutes;

    const minHours = parseInt(min.split(":")[0]);
    const minMinutes = parseInt(min.split(":")[1]);
    const minTotalMinutes = minHours * 60 + minMinutes;

    const maxHours = parseInt(max.split(":")[0]);
    const maxMinutes = parseInt(max.split(":")[1]);
    const maxTotalMinutes = maxHours * 60 + maxMinutes;

    if (
      selectedTotalMinutes < minTotalMinutes ||
      selectedTotalMinutes > maxTotalMinutes
    ) {
      setIsValidTime(false);
      setSelectedTimee(combinedDateTime2);
      setSelectedTimeRight(combinedDateTime.format("HH:mm"));
    } else {
      setSelectedTimee(newValue);
      setIsValidTime(true);
      setSelectedTimeRight(newValue.format("HH:mm"));
    }
  };

  useEffect(() => {
    let newTempsPreparation: string | undefined;

    if (isDeliveryMode && deliveryDuration) {
      const addedDuration = deliveryQuote?.duration || 0;
      newTempsPreparation = calculateTotalDuration(
        deliveryDuration,
        addedDuration
      );
    } else {
      newTempsPreparation = prepareDuration;
    }

    setTempsPreparation(newTempsPreparation);

    setFees(centimesToEuros(deliveryQuote?.fee || 0));
  }, [deliveryQuote, restaurantCart]);

  const checkRestaurantDelivery = async (restaurant: Restaurant) => {
    deliveryApi
      .checkRestaurantDelivery(
        String(restaurant?.id),
        deliveryAddress,
        userInfo.phoneNumber,
        (supTotal + fees).toFixed(2)
      )
      .then((res) => {
        setDeliveryQuote(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const PrepareFor = isMaintenantChecked
    ? selectedTimeRightISO(selectedTimeRight)
    : null;

  return (
    <div className="PaiementPage">
      <section>
        <AppNavbar />
        <div className="body-wrapper">
          <div className="TopPageHeader">
            <div onClick={() => navigate(-1)}>
              <div>
                <img src={LeftArrow} alt="" />
                <p>Paiement</p>
              </div>
            </div>
          </div>
          <main className="paimentSection">
            <div className="body-paiment">
              <div className="body-wrapper">
                <div className="map-location">
                  <div className="restaurant-name">
                    <p>
                      {restaurantId !== null
                        ? restaurantCart?.restaurant?.nom
                        : ""}
                    </p>
                  </div>
                  <div className="location-adresse">
                    <p>
                      {restaurantId !== null
                        ? restaurantCart?.restaurant?.address
                        : ""}
                    </p>
                  </div>
                  <div className="map-convas">
                    <div className="map">
                      <GoogleMap
                        options={{
                          disableDefaultUI: true, // Disable default UI controls
                          draggable: false, // Disable dragging
                          clickableIcons: false, // Disable clickable icons
                        }}
                        mapContainerStyle={style}
                        zoom={15}
                        center={center}
                      >
                        {showMarker && (
                          <Marker opacity={1} options={{}} position={center} />
                        )}
                      </GoogleMap>
                    </div>
                    <div className="location-details">
                      <div className="distance">
                        {/* <img src={DistanceIcon} alt="" /> */}
                        <div>
                          {/* <p>Distance</p>
                          <p>0,2 Km</p> */}
                        </div>
                      </div>
                      <div className="delivery-type">
                        <p className="label">
                          {" "}
                          {isMaintenantChecked ? "Planifier" : "Maintenant"}
                        </p>
                        <p>
                          <b>
                            {getOrderTypeText(restaurantCart?.mode || 0)}
                            {restaurantCart?.restaurant?.tempsPrep && (
                              <b>, prête dans : {tempsPreparation} min</b>
                            )}
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {restaurantCart?.mode !== MODE_DELIVERY && (
                  <div className={`payment-methode-container  show-card `}>
                    <p className="block-title">Méthode de Planification</p>
                    <div
                      className="second-Input"
                      style={{
                        gap: "15px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className="onsite-payment  payment-methode-field ">
                        <input
                          type="radio"
                          name="payment-planification"
                          id="maintenant"
                          value="maintenant"
                          checked={!isMaintenantChecked}
                          onChange={handlePlanificationMethodChange}
                        />
                        <label htmlFor="onsite">Maintenant</label>
                        {!isMaintenantChecked && (
                          <div style={{ paddingLeft: "30px" }}>
                            <p>Prête dans : {tempsPreparation} min</p>
                          </div>
                        )}
                      </div>
                      <div className="onsite-payment payment-methode-field">
                        <input
                          type="radio"
                          name="payment-planification"
                          id="planifier"
                          value="planifier"
                          checked={isMaintenantChecked}
                          onChange={handlePlanificationMethodChange}
                        />
                        <label htmlFor="onsite">Planifier</label>
                        {isMaintenantChecked && (
                          <div className="chechkingg">
                            <p>
                              Horaire : {OpenTime.format("HH:mm")} a{" "}
                              {ClosedTime.format("HH:mm")}
                            </p>
                            <div className="field-time">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={["MobileTimePicker"]}
                                >
                                  <DemoItem>
                                    <MobileTimePicker
                                      value={value}
                                      onChange={handleTimeChange}
                                      ampm={false}
                                    />
                                  </DemoItem>
                                </DemoContainer>
                              </LocalizationProvider>
                              <img src={clockIcon} alt="" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className={`payment-methode  show-card `}>
                  {restaurantCart?.mode !== MODE_DELIVERY && (
                    <div>
                      <p className="block-title">Méthode de paiement</p>
                      <div className="payments-wrapper">
                        {restaurantCart?.restaurant?.isOnSpotPaymentActive && (
                          <div className="onsite-payment">
                            <input
                              type="radio"
                              id="onsite"
                              name="payment-methode"
                              value="onsite"
                              checked={!isOnlinePaymentChecked}
                              onChange={handlePaymentMethodChange}
                            />
                            <label htmlFor="onsite">Sur place</label>
                          </div>
                        )}
                        {restaurantCart?.restaurant?.isStripeActive && (
                          <div className="online-payment">
                            <input
                              type="radio"
                              id="online"
                              name="payment-methode"
                              value="online"
                              checked={isOnlinePaymentChecked}
                              onChange={handlePaymentMethodChange}
                            />
                            <label htmlFor="online">En ligne</label>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="payment-info">
                    <div className="sous-total">
                      <p className="label">Sous-total</p>
                      <p className="value">{supTotal.toFixed(2)} €</p>
                    </div>

                    <div className="frais">
                      <p className="label">Frais</p>
                      <p className="value">{fees.toFixed(2)} €</p>
                    </div>
                    <div className="total">
                      <p className="total-label">Total</p>
                      <p className="total-value">
                        {(supTotal + fees).toFixed(2)} €
                      </p>
                    </div>
                  </div>
                  <div className="card-section">
                    <Elements
                      stripe={stripePromise}
                      options={{
                        mode: "payment",
                        amount: 350,
                        currency: "eur",
                      }}
                    >
                      <CheckoutForm
                        restaurantCart={restaurantCart}
                        setqrcode={(str: string) => setqrcode(str)}
                        setcommand={(command: any) => setcommand(command)}
                        setpromptCommand={(value: boolean) =>
                          setpromptCommand(value)
                        }
                        isOnlinePaymentChecked={isOnlinePaymentChecked}
                        setShowProcessingCommand={() =>
                          setShowProcessingCommand(true)
                        }
                        isValidTime={isValidTime}
                        isMaintenantChecked={isMaintenantChecked}
                        selectedTimeRight={selectedTimeRight}
                        quoteId={deliveryQuote?.id}
                        estimatedDeliveryQuoteMinutes={convertTimeToMinutes(
                          tempsPreparation || "00:00"
                        )}
                        supTotal={supTotal}
                        fees={fees}
                      ></CheckoutForm>
                    </Elements>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          </main>

          {showProcessingCommand && (
            <div className="ProcessingCommandComponent">
              <ProcessingCommand
                resturant={restaurantCart?.restaurant?.nom}
                distance={restaurantCart?.restaurant?.address}
                tempsPrep={tempsPreparation}
                placement={getDiningModeLabel(restaurantCart?.mode || 0)}
              />
            </div>
          )}

          {promptCommand && (
            <div className="InvoiceCommandComponent ">
              <InvoiceCommand
                clearItemStore={removeRestaurantFromSavedCategories}
                command={command}
                qrcode={qrcode}
                restaurantCart={restaurantCart}
                PrepareFor={PrepareFor}
              />
            </div>
          )}
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    savedCategories: state.cart.savedCategories,
    location: state.auth.location,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getmyprofile: () => dispatch(getmyprofile()),
    pushCategory: (category: serviceMenuModels.Category) =>
      dispatch(pushCategory(category)),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
